.main-header {
	width: 100%;
	height: @header-height;
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	background-color: #fff;
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	align-items: center;

	&:not(.no-shadow) {
		box-shadow: @shadow;
	}

	.content-wrapper {
		display: flex;
		align-items: center;
		width: 100%;
	}

	.top-nav-wrap,
	.search-and-cart-wrap {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: flex-end;
	}

	.top-nav-wrap {
		padding-bottom: 10px;
		position: relative;
		> span {
			flex: 1 0 auto;
		}

		.ant-ribbon-wrapper {
			position: absolute;
			left: 14px;
			top: -30px;
		}

		.ant-ribbon {
			font-size: 12px;
			height: 24px;
			line-height: 26px;
		}

		@media screen and (max-width: @screen-xl) {
			.fb-text {
				display: none;
			}
		}
	}

	.top-nav {
		display: flex;
		padding: 0;
		margin: 0 20px;
		align-items: center;
		max-width: 580px;

		> li {
			display: inline-block;
			border-right: 2px solid @border-color;
			padding: 2px 5px;
			max-height: 25px;
			&:last-child {
				border-right: 0;
			}
		}

		a:not(.ant-btn) {
			display: block;
			padding: 2px 15px;
			line-height: 1.125em;
			font-weight: bold;
			text-align: center;

			@media screen and (max-width: @screen-xl) {
				padding: 2px 10px;
			}
		}

		.ant-btn-sm {
			@media screen and (max-width: @screen-xl) {
				padding: 2px 15px;
			}
		}
	}

	.shopping-cart-link {
		display: inline-block;
		padding: 5px 20px;
	}

	.site-nav {
		flex: 2 1 auto;
		padding-left: 30px;

		@media screen and (min-width: @screen-xl-min + 1) {
			max-width: 1040px;
			width: 67.8616%;
		}
	}
}

.omms-user-menu-ui {
	width: 220px;
	margin-top: 10px;

	> .ant-menu {
		border-radius: 12px;
		background-color: @white;
		box-shadow: 0px 0px 40px #00000029;
		padding: 5px 0;

		.ant-menu-item {
			position: relative;
			padding: 5px 20px 5px 55px;
			margin: 0;
			height: auto;
			line-height: 35px;
			border-bottom: 1px solid @lightgray6;
			color: @blue;
			font-weight: bold;

			&:last-child {
				border-bottom: 0;
			}
		}
	}

	.omegamed-asset-ico {
		position: absolute;
		top: 50%;
		left: 20px;
		margin-top: -9px;
	}
}

.omms-user-avatar-ui {
	border: 2px solid @lightgray6;
	line-height: 36px;
	width: 40px;
	text-align: center;
	border-radius: 8px;
}

.omms-user-menu-btn-ui {
	display: flex;
	align-items: center;
}

.omms-user-greet {
	font-weight: bold;
	color: @blue;
	display: inline-block;
	padding: 0 0 0 15px;
	line-height: 19px;
}
