.form-wrapper {
	max-width: 640px;
	margin: 0 auto 30px;

	h1 {
		margin-bottom: 30px;
	}

	.ant-form {
		padding-top: 30px;
	}

	&.form-blocks {
		margin-top: 30px;
		margin-bottom: 40px;
		background-color: @white;
		box-shadow: 0px 0px 99px #00000029;
		max-width: 480px;
		padding: 30px;
		border-radius: 10px;
	}
}

.form-submit-btn {
	padding-top: 30px;
	text-align: center;

	// Make sure the btn component is in block
	.ant-btn {
		@media screen and (min-width: @screen-md) {
			max-width: 320px;
		}
	}
}

.number-infor-format {
	line-height: 48px;
	> * {
		vertical-align: middle;
	}

	.ant-input {
		@media screen and (max-width: @screen-xs) {
			padding: 10px;
			max-width: 100px !important;
		}
	}
}
