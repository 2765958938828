.ant-btn-sm {
	font-size: 15px;
	line-height: 20px;
	padding: 8px 20px;
	border-radius: 10px;
}

textarea.ant-input {
	border-radius: 5px;
	min-height: 220px;
}

.ant-input-affix-wrapper {
	padding-left: 20px;
	padding-right: 20px;
	border-width: 2px;

	&:hover,
	&:focus,
	&-focused {
		border-color: @purple;
		border-right-width: 2px !important;
		box-shadow: none;
	}

	& > .ant-input {
		border-radius: 0;
	}
}

.ant-input,
.ant-picker {
	padding-left: 20px;
	padding-right: 20px;
	border-width: 2px;
}

.ant-input {
	&.with-ctrl-handlers {
		border-radius: 0;
		height: 48px;
		padding: 10px 20px;

		&:hover {
			border-color: @border-color;
			border-right-width: 1px !important;
		}
	}
}

.num-format-handler {
	display: inline-block;
	line-height: 44px !important;
	width: 50px;
	font-size: 32px;
	font-weight: bold;
	text-align: center;
	border: 2px solid @border-color !important;
	vertical-align: middle;
	cursor: pointer;

	&.decrement {
		padding-bottom: 2px;
		line-height: 42px !important;
		margin-right: -2px;
		border-right: 0;
	}

	&.increment {
		margin-left: -2px;
		border-left: 0;
	}
}

.ant-checkbox-inner {
	border-width: 2px;
}

.ant-checkbox-checked {
	.ant-checkbox-inner {
		background-color: @white;
		border: 2px solid @purple;
		&::after {
			border-width: 5px;
			border-color: @blue;
			top: 48%;
			left: 31%;
			height: 22px;
			width: 12px;
		}
	}

	&.ant-checkbox-disabled {
		.ant-checkbox-inner {
			background-color: @lightgray6;
			border-color: @lightgray6 !important;

			&::after {
				border-color: @lightgray5;
			}
		}
	}
}

.ant-checkbox + span {
	padding: 0 20px;
}

.ant-form-item {
	margin-bottom: 20px;
}

.ant-form-item-label >  {
	padding-bottom: 15px !important;
	label {
		line-height: 1.375em;
	}
}

// BUTTONS
.ant-btn-lg {
	padding: 12.5px 20px;
	line-height: 1.4em;
}

span.ant-radio + * {
	padding-left: 15px;
	padding-right: 15px;
	vertical-align: super;
}

.ant-picker {
	min-width: 200px;
}

.ant-btn-icon-only:not(.ant-btn-circle).ant-btn-sm {
	border-radius: 5px;
}

.ant-pagination-item {
	width: 28px;
	height: 28px;
	min-width: 28px;
	line-height: 25px;
	border-radius: 0;
	border: 0;
	a {
		color: @blue;
		font-weight: bold;
	}
}

.ant-pagination-prev,
.ant-pagination-next {
	font-family: 'Lasiver', Arial;
	font-weight: 500;
}

.ant-pagination-prev {
	margin-right: 20px;
}
.ant-pagination-next {
	margin-left: 20px;
}

.ant-pagination-item-active {
	background-color: @blue;
	a,
	&:focus a {
		color: @white;
	}
}

.ant-carousel {
	height: 100%;
	width: 100%;

	.slick-slider,
	.slick-list,
	.slick-track,
	.slick-slide > div,
	.slick-slide > div > div {
		height: 100%;
		width: 100%;
	}

	.slick-slider {
		position: static;
	}
}

.ant-modal-mask {
	background-color: fade(@black, 60%);
}

.ant-modal-close {
	top: 15px;
	right: 15px;
}

.ant-form-vertical {
	.ant-form-item-label {
		padding-bottom: 5px !important;
	}
}

.ant-input-group-addon:first-child {
	padding: 0 20px;
	border-width: 2px;
	line-height: 1em;
}

.ant-upload.ant-upload-select-picture-card {
	width: 180px;
	height: 180px;
}

.ant-collapse {
	font-size: 14px;
}
