@font-face {
    font-family: 'Lasiver';
    src: url('../../../fonts/Lasiver-Regular.woff') format('woff'),
        url('../../../fonts/Lasiver-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lasiver';
    src: url('../../../fonts/Lasiver-Medium.woff') format('woff'),
        url('../../../fonts/Lasiver-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lasiver';
    src: url('../../../fonts/Lasiver-Bold.woff') format('woff'),
        url('../../../fonts/Lasiver-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
