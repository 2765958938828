.main-footer {
	background-color: #e1e3ed;
	min-height: @footer-height;
	width: 100%;
	z-index: 9;
	padding: 10px;

	.content-wrapper {
		padding-top: 20px;
		padding-bottom: 20px;
		line-height: 20px;
	}

	.copy-right {
		border-top: 2px solid #c2c6d8;
	}

	.footer-links {
		display: flex;

		> div {
			width: 42.5%;
			// &:last-child {
			// 	width: 25%;
			// }

			@media screen and (max-width: 991px) {
				width: 50%;
			}
		}

		ul {
			display: block;
			padding: 0;
			margin: 0 auto;

			> li {
				margin: 0;
				display: inline-block;
				width: 50%;
			}
		}

		a {
			display: inline-block;
			padding: 5px 0;

			.omegamed-asset-ico {
				vertical-align: middle;
				margin-right: 10px;
			}
		}
	}

	.hotline {
		font-size: 24px;
		font-weight: bold;
		white-space: nowrap;
		line-height: 1em;

		.span:last-child {
			vertical-align: middle;
		}
	}
}

.omms-footer-category-links {
	.omms-category-list {
		display: flex;
		flex-flow: row wrap;
		margin-left: -20px;
		margin-right: -20px;

		> div {
			padding-left: 20px;
			padding-right: 20px;
			line-height: 20px;

			a {
				display: inline-block;
				text-transform: uppercase;
				padding: 5px 0;
			}

			span {
				display: block;
				padding-bottom: 5px;
			}

			@media screen and (min-width: @screen-xxl-min + 1) {
				width: 20%;
			}

			@media screen and (max-width: @screen-xxl-min) {
				width: 25%;
			}

			@media screen and (max-width: @screen-lg-min) {
				width: 33.33%;
			}

			@media screen and (max-width: @screen-md-min) {
				width: 50%;
			}

			@media screen and (max-width: @screen-xs-min) {
				width: 50%;
			}
		}
	}

	// .five-column-list > div { width: 20%; }
	// .four-column-list > div { width: 25%; }
	// .three-column-list > div { width: 33.33%; }
	// .two-column-list > div { width: 50%; }
	// .one-column-list > div { width: 100%; }
}
