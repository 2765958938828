.logo {
	position: relative;
	margin-right: auto;
	display: inline-block;

	> img {
		max-width: 100%;
		min-width: 96px;
	}

	@media screen and (min-width: @screen-lg-min + 1) {
		width: 34%;
		.plain-logo {
			display: none;
		}
	}

	@media screen and (max-width: @screen-lg-min) {
		padding-right: 20px;
		.logo-with-text {
			display: none;
		}
	}
}
