html,
body {
    height: 100%;
}

.text-right {
    text-align: right !important;
}
.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
.text-justify {
    text-align: justify !important;
}
.text-toproper {
    text-transform: lowercase;
}
.text-toproper::first-letter {
    text-transform: uppercase;
}

.content-wrapper {
    max-width: @content-width;
    margin: 0 auto;
    padding: 0 20px;
}

.omegamed-asset-ico {
    line-height: inherit;
    display: inline-block;
    line-height: 1;

    &.pointer {
        cursor: pointer;
    }
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline;
    margin: 0;
    padding: 0;
    line-height: 1.25em;
    color: @blue;
}

.link-button:hover,
.link-button:focus {
    text-decoration: none;
    outline: none;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header-title-image-banner {
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #102751;

    @media screen and (max-width: 1920px) {
        background-size: cover;
    }

    &:before {
        display: block;
        content: " ";
    }
}

.ant-carousel {
    height: 100%;
    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide > div {
        height: 100%;
    }

    .slick-list .slick-slide {
        padding: 0 10px;
    }

    .slick-prev,
    .slick-next {
        width: 72px;
        height: 72px;
        background-color: fade(@white, 50%);
        z-index: 10;
        margin-top: -36px;

        &:hover {
            background-color: fade(@white, 75%);
        }
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }
}

.omms-banners-ui {
    width: 70.4574%;

    .ant-carousel {
        .slick-prev,
        .slick-next {
            background-color: fade(@white, 50%);

            &:hover {
                background-color: fade(@white, 75%);
            }
        }
    }

    .banner-item {
        position: relative;
        background-color: @red;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        margin-right: 10px;
    }
}

.omms-badge {
    background-color: @green2;
    border-radius: 6px;
    line-height: 29px;
    color: @white;
    padding: 0 15px;
    display: inline-block;
}

.omms-ad-block {
    background-color: @blue;
    max-width: 820px;
    margin: 0 auto;
    padding: 40px 30px;
    display: flex;
    align-items: center;

    .ad-message {
        flex-grow: 2;
        text-align: center;
        color: @white;
        font-size: 32px;
        font-weight: 500;
    }
}

.omms-success-modal-btns {
    padding: 15px 0 30px;
    .ant-btn {
        min-width: 180px;
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.omms-page-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 12;
    margin-top: -32px;
    margin-left: -35px;
}

.chevron-down-ico {
    transform: rotate(90deg);
}

.download-ico {
    transform: rotate(-90deg);
}

.omms-checkout-table {
    margin-bottom: 30px;

    .ant-table {
        background-color: transparent;
    }
    .ant-table-thead > tr > th {
        background-color: transparent;
        font-size: 1.375em;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        padding: 20px;
        color: @blue;
        font-weight: bold;

        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
    }
}

.omms-group-header-filters {
    .ant-btn {
        height: 72px;
        padding: 20px 30px;
        line-height: 32px;
        min-width: 150px;
        border-radius: 0;

        &:last-child {
            margin-left: 10px;
        }

        &.ant-btn-inactive {
            background-color: #e1e3ed;
            border-color: #e1e3ed;
            color: @blue;
        }
    }
}

.route-loader {
    padding: 60px;
    text-align: center;
}

.omms-category-group-ui {
    position: relative;
    padding: 20px 30px;
    background-color: @white;
    box-shadow: 0px 0px 99px #00000029;
    margin-bottom: 40px;

    &.red-bg {
        background-color: @red;

        .omms-cagetory-group-heading {
            .heading-title {
                color: @white;
            }
        }

        .omms-cagetory-group-body {
            .omms-item-image {
                background-color: @white;
            }
            .omms-item-details,
            .omms-item-details .price {
                color: @white;
            }
        }
    }
}

.omms-cagetory-group-heading {
    display: flex;
    align-items: center;

    .heading-title {
        font-size: 2em;
        font-weight: bold;
        flex-grow: 2;
        color: @blue;
    }

    .view-more {
        width: 120px;
        text-align: right;
    }
}

.omms-cagetory-group-body {
    padding-top: 20px;
    padding-bottom: 0;
    min-height: 240px;

    .ant-carousel {
        margin-left: -10px;
        margin-right: -10px;
    }
}

.omms-item-frame {
    position: relative;
    display: block;

    &:not(.landscape) {
        margin: 0 auto 20px;
        max-width: 230px;
    }

    &.landscape {
        margin-left: -1px;
        margin-bottom: 20px;
    }
}

.omms-item-discount {
    position: absolute;
    background: transparent url("../../../../style/media/discount-ribbon.svg") no-repeat top center;
    background-size: cover;
    padding-top: 15px;
    top: 0;
    right: 16px;
    width: 54px;
    height: 70px;

    > span {
        display: block;
        text-align: center;
        position: relative;
        line-height: 1.2em;
        font-weight: bold;

        > span {
            display: block;
        }
    }

    @media screen and (max-width: @screen-xxl) {
        padding-top: 12px;
        right: 8px;
        width: 45px;
        height: 60px;
    }

    &.large {
        width: 97px;
        height: 126px;

        > span {
            font-size: 24px;
        }
    }
}

.omms-item-image {
    position: relative;
    margin-bottom: 10px;
    max-height: 325px;
    border: @border-color 1px solid;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(.no-image) {
        background-size: cover;
    }

    &.no-image {
        background-image: url("../../../../style/media/no-image.svg");
    }

    > div:not(.ant-spin) {
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        width: 100%;
    }

    &::before {
        content: " ";
        display: block;
        padding-top: 141.3043%;
    }
}

.omms-item-details {
    display: flex;
    align-items: center;
    flex-flow: wrap-reverse;

    > * {
        width: 100%;
    }

    .name {
        line-height: 1.25em;
    }

    .sold {
        color: @lightgray7;
        white-space: nowrap;
    }

    .price {
        font-size: 18px;
        color: @blue;
        font-weight: bold;
    }

    @media screen and (max-width: @screen-xxl) {
        .flare svg {
            width: 14px;
            height: 18px;
        }
    }

    @media screen and (max-width: @screen-xxl) {
    }
}

.chevron-left-ico {
    transform: matrix(-1, 0, 0, -1, 0, 0);
}
