/*
 * Generate Margin Class
 * margin, margin-top, margin-bottom, margin-left, margin-right
 */

 .margin (@label, @size: 1, @key: 1, @increment: 5) when (@size =< 80){
    .m-@{key} {
        margin: @size !important;
    }
    
    .m-t-@{key} {
        margin-top: @size !important;
    }
    
    .m-b-@{key} {
        margin-bottom: @size !important;
    }
    
    .m-l-@{key} {
        margin-left: @size !important;
    }
    
    .m-r-@{key} {
        margin-right: @size !important;
    }
    
    .margin(@label - @increment; @size + @increment; @key + @increment; @increment);
}

.margin(80, 0px, 0);
    
/*
 * Generate Padding Class
 * padding, padding-top, padding-bottom, padding-left, padding-right
 */

.padding (@label, @size: 1, @key:1) when (@size =< 80){
    .p-@{key} {
        padding: @size !important;
    }
    
    .p-t-@{key} {
        padding-top: @size !important;
    }
    
    .p-b-@{key} {
        padding-bottom: @size !important;
    }
    
    .p-l-@{key} {
        padding-left: @size !important;
    }
    
    .p-r-@{key} {
        padding-right: @size !important; 
    }
    
    .padding(@label - 4; @size + 4; @key + 4);
} 

.padding(80, 0px, 0);

/*
 * Font-colors
 */
@length : length(@colors);
.font-color-variation(@names; @colors; @index) when (iscolor(extract(@colors, @index))) and (@index > 0) {
    .font-color-variation(@names; @colors; (@index - 1)); // decrement.

    @name  : extract(@names, @index);
    @color : extract(@colors, @index);

    .c-@{name} {
        color: @color !important;
    }
}

.font-color-variation(@names; @colors; @length);

/*
 * Generate Font-Size Classes (8px - 36px)
 */
.font-size (@label, @size: 8, @key:10) when (@size =< 36){
    .f-@{key} {
        font-size: @size !important;
    }
    
    .font-size(@label - 1; @size + 1; @key + 1);
} 

.font-size(36, 8px, 8);

/*
 * Font Weight
 */
.f-bold { font-weight: bold !important; }
.f-medium { font-weight: 500 !important; }
.f-normal { font-weight: normal !important; }

/*
 * Position Classes
 */
.p-relative { position: relative !important; }
.p-absolute { position: absolute !important; }
.p-fixed { position: fixed !important; }
.p-static { position: static !important; }

/*
 * Overflow
 */
.o-hidden { overflow: hidden !important; }
.o-visible { overflow: visible !important; }
.o-auto { overflow: auto !important; }

/*
 * Display
 */
.d-block { display: block !important; }
.d-iblock { display: inline-block !important; }
.d-flex { display: flex; } 

/*
 * flex rules
 */
 .flex-align-center { align-items: center; }
 .flex-align-start { align-items: flex-start; }
 .flex-align-end { align-items: flex-end; }

 .flex-jcenter { justify-content: center; }
 .flex-jbetween { justify-content: space-between; }
 .flex-jevenly { justify-content: space-evenly; }
 

/*
 * Background Colors
 */
.bg-black-trp { background-color: rgba(0,0,0,0.1) !important; }

/*
 * Border
 */
.b-0 { border: 0 !important; }
.border-top { border-top: 1px solid @border-color; }
.border-bottom { border-bottom: 1px solid @border-color; }

/*
 * width
 */
.w-100 { width: 100% !important; }

/*
 * Border Radius 
 */ 
.brd-2 { border-radius: 2px; }

/*
 * Vertical Alignment
 */
 .va-middle { vertical-align: middle; } 
 .va-sub { vertical-align: sub; } 
 .va-top { vertical-align: top; } 
 .va-bottom { vertical-align: bottom; } 
 .va-baseline { vertical-align: baseline; } 

 .table-responsive {
    max-width: 100%;
    overflow-x: auto;
}

.text-nowrap { white-space: nowrap; }

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.badge {
    padding: 0px 8px;
    font-size: 14px;
    color: @white;
    display: inline-block;
    border-radius: 10px;
    line-height: 26px;
    background-color: @blue;
    min-width: 47px;
    text-align: center;

    &.upcoming {
        background-color: @mint-green;
    }
    &.past {
        background-color: @gray;
    }

    &.pending-list {
        background-color: @red;
    }
    &.merged-list {
        background-color: @darkblue2;
    }
}


@media (min-width: (@screen-sm + 1px)) {
    .max-width-200 { 
        margin: 0 auto;
        max-width: 200px; 
    }
    .min-width-200 { 
        min-width: 200px; 
    }
    .min-width-250 { 
        min-width: 250px; 
    }
}