.ant-btn {
    // Button colors 
    &.btn-red {
        background-color: @red;
        border-color: @red;
        color: @white;
    
        &:hover, &:focus, &:active {
            background-color: lighten(@red, 25%);
            border-color: lighten(@red, 25%);
        }
    }

    &.btn-purple {
        background-color: @purple;
        border-color: @purple;
        color: @white;
    
        &:hover, &:focus, &:active {
            background-color: darken(@purple, 10%);
            border-color: darken(@purple, 10%);
        }
    }

    &.btn-yellow {
        background-color: @yellow;
        border-color: @yellow;
        color: @blue;
    
        &:hover, &:focus, &:active {
            background-color: darken(@yellow, 10%);
            border-color: darken(@yellow, 10%);
        }
    }

    &.btn-gray {
        background-color: @lightgray6;
        border-color: @lightgray6;
        color: @blue;
    
        &:hover, &:focus, &:active {
            background-color: darken(@lightgray6, 10%);
            border-color: darken(@lightgray6, 10%);
        }
    }


    &.btn-normal {
        font-weight: normal;
        border-radius: 5px;
    }
    
    &.btn-max-250px {
        max-width: 250px;
    }
}

.oms-btn-style {
    border-radius: 13px;
    border: 1px solid #E1E3ED;
    padding: 16px 20px;
    line-height: 30px;
    display: inline-block;
}