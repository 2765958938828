.omms-ad-strips-ui {
	position: relative;
	overflow: hidden;
	background: @blue;
	text-align: center;
	cursor: pointer;

	.ad-to-copy {
		width: 1920px;
		height: 200px;
		bottom: calc(100% + 10px);

		&.loaded {
			display: none;
		}

		> div {
			display: flex;
			align-items: center;
			width: 100%;
			max-width: 1618px;
			margin: 0 auto;
			height: 100%;
		}

		.logo-ui {
			width: 160px;
			padding-right: 20px;
			> img {
				width: 100%;
			}
		}

		.text-content {
			flex-grow: 2;
			font-size: 72px;
			font-weight: bold;
			color: @white;
			text-align: center;
		}

		.btn-ad {
			width: 200px;
			.ant-btn {
				height: 80px;
				padding: 20px;
				line-height: 40px;
				font-size: 32px;
				font-weight: bold;
			}
		}
	}

	img {
		width: 100%;
		max-width: 1340px;
	}
}
