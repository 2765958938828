body {
    background-color: @lightgray1;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .main-body {
        flex: 1 o auto;

        &::before {
            display: block;
            content: ' ';
            padding-top: @header-height;
        }
    }

    .main-footer {
        flex-shrink: 0;
    }
}

.main-body {
    z-index: 8;

    .content-wrapper {
        padding-top: 30px;
        padding-bottom: 40px;
    }

    .full-width-content {
        background-color: @white;
        max-width: none;
        padding: 0;
    }
}