.omms-modal-ui {
	@media screen and (min-width: @screen-lg-min) {
		width: 920px !important;
	}

	@media screen and (max-width: @screen-md-max) {
		width: 820px !important;
	}

	@media screen and (max-width: @screen-sm-max) {
		width: 640px !important;
	}

	div.ant-modal-header {
		padding: 12px 16px 10px;

		.ant-modal-title {
			display: flex;
			align-items: center;
		}
	}

	div.ant-modal-body {
		padding: 0 40px;
		border-radius: 6px;
		overflow: hidden;

		p {
			font-size: 16px;
			font-weight: 500;
			color: @gray;

			img {
				max-width: 480px;
			}
		}
	}

	&.without-modal-title {
		.ant-modal-body {
			&:before {
				content: ' ';
				display: block;
				height: 60px;
				margin: 0 -24px;
			}
		}
	}

	.ant-modal-footer {
		min-height: 30px;
		padding: 20px 30px;
		.ant-btn {
			min-width: 200px;
		}
		button + button {
			margin-left: 16px;
		}
		&:empty {
			border-top: 0;
		}
	}

	.ant-modal-close-x {
		width: 48px;
		height: 48px;
		line-height: 50px;
	}

	&.no-footer-buttons {
		.ant-modal-body {
			padding-bottom: 24px;

			img {
				max-width: 100%;
			}
		}
		.ant-modal-content > .ant-modal-footer {
			display: none;
		}
	}
}

.oms-image-preview {
	&.without-modal-title .ant-modal-body {
		padding: 10px;
		&:before {
			content: '';
			height: auto;
		}

		img {
			max-width: 100%;
		}
	}

	.ant-modal-footer {
		display: none;
	}

	.ant-modal-close {
		background-color: rgba(@white, 50%);
		border-radius: 50%;
	}
}
